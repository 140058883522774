<script setup lang="ts">
import type MobileMenu from "./MobileMenu.vue";

const mobibleMenuRef = ref<typeof MobileMenu>();

function openMobileMenu() {
    mobibleMenuRef.value?.show();
}

defineEmits<{ (e: "showPortfolio"): void }>();
</script>

<template>
    <header class="bg-white container-page">
        <nav class="flex justify-between items-center p-3">
            <img
                src="/prospektus-logotipo.png"
                class="w-16 h-auto lg:w-24 object-cover"
                alt="prospektus-logotipo"
            />

            <ul class="flex-1 sm:flex hidden justify-end gap-5">
                <li>
                    <a href="#aboutUs" class="menu-item"> Quem somos </a>
                </li>
                <li>
                    <a href="#services" class="menu-item"> Serviços </a>
                </li>
                <li>
                    <a href="#clients" class="menu-item"> Clientes </a>
                </li>
                <li>
                    <a class="menu-item" @click="$emit('showPortfolio')"> Portfolio </a>
                </li>
            </ul>

            <button
                type="button"
                class="material-symbols-outlined sm:hidden text-primary text-4xl"
                @click="openMobileMenu"
            >
                menu
            </button>
        </nav>
    </header>

    <MobileMenu ref="mobibleMenuRef" />
</template>
