import type { EmailSender } from "~/lib/services/email_sender";

export class NodeMailerSender implements EmailSender {
    private transporter;

    constructor() {
        this.transporter = useMail();
    }

    send(name: string, subject: string, text: string): void {
        this.transporter.send({
            from: `${name} <prospektus@prospektus.co.ao>`,
            subject: subject,
            text: text,
        });
    }

    get sender() {
        return this.transporter;
    }
}
