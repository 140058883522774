import { Email } from "./email";
import { Field } from "./field";

export class ContactForm {
    name: Field;
    email: Email;
    subject: Field;
    message: Field;

    constructor() {
        this.name = new Field(10);
        this.email = new Email();
        this.subject = new Field(10);
        this.message = new Field(20);
    }

    isValid() {
        return Object.values(this).every((field: Field) => field.isValid());
    }

    isInValid() {
        return !this.isValid();
    }

    reset() {
        Object.values(this).forEach((field: Field) => field.clear());
    }
}
