export class Field {
    value: string;
    valid: boolean;
    minLength: number;
    errorMessage: string;

    constructor(minLength?: number) {
        this.value = "";
        this.valid = false;
        this.minLength = minLength ?? 0;
        this.errorMessage = "";
    }

    validate() {
        if (!this.isValid() && this.value.length >= 1) {
            this.errorMessage = `O campo obrigatório deve ter no mínimo ${this.minLength} caracteres`;
            return;
        }

        this.valid = this.isValid();
        this.errorMessage = "";
    }

    isValid() {
        return this.value.length >= this.minLength;
    }

    clear() {
        this.value = "";
        this.valid = false;
        this.errorMessage = "";
    }
}
