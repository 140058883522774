<script setup lang="ts">
import { Carousel } from "~/lib/carousel";

interface Props {
    banners: string[];
}

const props = defineProps<Props>();
const carousel = ref<Carousel>(new Carousel(props.banners));

function startCarousel() {
    setInterval(() => {
        carousel.value.nextImage();
    }, 5000);
}

onMounted(() => {
    startCarousel();
});
</script>
<template>
    <section class="relative h-[600px] sm:h-[800px] text-white">
        <img
            :src="carousel.currentImage"
            class="w-full h-full object-cover mx-auto"
            alt="prospektus-website-banner"
        />

        <slot></slot>

        <CarouselIndicator :carousel="carousel" />
    </section>
</template>
