export function createMessage(name: string, email: string, body: string) {
    const message = `

    Nome: ${name}
    Email: ${email}


    ${body}

    `;
    return message;
}
