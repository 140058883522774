<script setup lang="ts">
import { Carousel } from "~/lib/carousel";

interface Props {
    images: string[];
}

const props = defineProps<Props>();
const dialogRef = ref<HTMLDialogElement>();
const carousel = reactive(new Carousel(props.images));

function show() {
    dialogRef.value?.show();
}

function close() {
    dialogRef.value?.close();
}

defineExpose({ show, close });
</script>

<template>
    <BaseDialog ref="dialogRef">
        <h1 class="h1 mb-8">Trabalhos realizados</h1>

        <div class="flex justify-center items-center">
            <span
                class="material-symbols-outlined text-6xl text-primary cursor-pointer"
                :class="carousel.currentIndex === 0 ? 'hidden' : ''"
                @click="carousel.previousImage()"
            >
                chevron_left
            </span>

            <div class="max-w-7xl h-[900px] flex items-center mx-auto">
                <img
                    :src="carousel.currentImage"
                    class="object-contain h-full mx-auto flex-1"
                    alt="portfolio-prospektus"
                />
            </div>
            <span
                class="material-symbols-outlined text-6xl text-primary cursor-pointer"
                :class="carousel.currentIndex === carousel.length - 1 ? 'hidden' : ''"
                @click="carousel.nextImage()"
            >
                chevron_right
            </span>
        </div>

        <CarouselIndicator :carousel="carousel" />
    </BaseDialog>
</template>
