export class Carousel {
    currentIndex: number;
    images: string[];

    constructor(images: string[]) {
        this.images = images;
        this.currentIndex = 0;
    }

    nextImage() {
        if (this.currentIndex === this.images.length - 1) {
            this.currentIndex = 0;
            return;
        }

        this.currentIndex++;
    }

    previousImage() {
        if (this.currentIndex === 0) {
            this.currentIndex = this.images.length - 1;
            return;
        }

        this.currentIndex--;
    }

    selectImage(index: number) {
        this.currentIndex = index;
    }

    isSelected(index: number) {
        return this.currentIndex === index;
    }

    get length() {
        return this.images.length;
    }

    get currentImage() {
        return this.images[this.currentIndex];
    }
}
