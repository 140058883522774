import type { EmailSender } from "./email_sender";

export class EmailService {
    sender: EmailSender;

    constructor(sender: EmailSender) {
        this.sender = sender;
    }

    sendEmail(name: string, subject: string, message: string) {
        this.sender.send(name, subject, message);
    }
}
