<script setup lang="ts">
import type Portfolio from "~/components/Portfolio.vue";
import type { CompayService } from "~/lib/models/company_service";
import type { CompanyValue } from "~/lib/models/company_value";
import type { SocialNetwork } from "~/lib/models/company_social_network";

import prospektus from "~/lib/data/prospektus.json";
import { ContactForm } from "~/lib/forms/contact_form";
import { EmailService } from "~/lib/services/email_service";
import { NodeMailerSender } from "~/lib/adapters/nodemailer/nodemailer";
import { createMessage } from "~/lib/helpers/create_message";

const values = ref<CompanyValue[]>(prospektus.values);
const services = ref<CompayService[]>(prospektus.services);
const missions = ref<string[]>(prospektus.missions);
const clients = ref<string[]>(prospektus.clients);
const banners = ref<string[]>(prospektus.banners);
const carouselBanners = ref<string[]>(prospektus.carousel_banners);
const socials = ref<SocialNetwork>(prospektus.socials);
const portfolioDialogRef = ref<typeof Portfolio>();

const sender = new NodeMailerSender();
const emailService = new EmailService(sender);
const form = reactive<ContactForm>(new ContactForm());

function sendMail() {
    const name = form.name.value;
    const email = form.email.value;
    const subject = form.subject.value;
    const text = form.message.value;

    const message = createMessage(name, email, text);

    try {
        emailService.sendEmail(name, subject, message);
    } catch (error) {
        alert("Ocorreu um erro ao enviar a mensagem");
    }

    alert("Mensagem enviada com sucesso");

    form.reset();
}
</script>
<template>
    <div>
        <TheHeader @show-portfolio="portfolioDialogRef?.show()" />
        <main>
            <section class="relative text-white">
                <Carousel :banners="banners">
                    <section class="banner-cover container-page">
                        <h1
                            class="font-bold text-2xl font-inter leading-9 text-center sm:w-[600px] sm:leading-[56px] sm:text-4xl sm:text-left"
                        >
                            Temos estrutura para atender quaisquer necessidades dos nossos clientes
                        </h1>

                        <p class="font-inter text-center sm:text-left sm:w-[500px] py-4">
                            Criamos soluções inovadoras que facilitam o dia a dia do cidadão e temos
                            diversas parcerias estratégicas com empresas e indústrias no exterior.
                        </p>

                        <a
                            :href="socials.whatsapp"
                            target="_blank"
                            class="btn btn-primary font-inter w-fit mx-auto sm:mx-0"
                        >
                            <i class="bi bi-whatsapp mr-2"></i>
                            Fale connosco via WhatsApp
                        </a>
                    </section>
                </Carousel>
            </section>

            <section id="aboutUs" class="space-y-4 mt-20 px-3">
                <h1 class="h1">Quem Somos</h1>
                <p
                    class="font-inter text-content opacity-80 mx-auto text-center w-[280px] sm:w-[600px]"
                >
                    {{ prospektus.aboutUs }}
                </p>
            </section>

            <section
                id="companyMissions"
                class="container-page sm:flex sm:flex-col lg:flex-row sm:items-center sm:justify-center sm:gap-8"
            >
                <img
                    class="mt-16 w-[300px] mx-auto object-cover sm:w-[600px] sm:mx-0"
                    src="/images/construction-bro-orange.png"
                    alt="prospektus-work-icon"
                />
                <ul class="mt-5 space-y-8 sm:w-[600px]">
                    <ListItem :items="missions" />
                </ul>
            </section>

            <section id="companyValues" class="mt-32 bg-light py-12 container-page">
                <div class="content-display">
                    <ValueItem
                        v-for="value in values"
                        :key="value.description"
                        :description="value.description"
                        :notes="value.notes"
                    />
                </div>

                <h1 id="services" class="h1 my-16 mx-auto max-w-sm">Explore os Nossos Serviços</h1>

                <div class="content-display">
                    <ListItem
                        v-for="service in services"
                        :key="service.title"
                        :title="service.title"
                        :items="service.items"
                        :icon-url="service.iconUrl"
                    />
                </div>
            </section>

            <section id="clients" class="container-page space-y-12 my-24">
                <h1 class="h1">Clientes</h1>
                <div class="grid grid-cols-1 items-center sm:grid-cols-4 gap-4">
                    <img
                        v-for="client in clients"
                        :key="client"
                        :src="client"
                        class="mx-auto object-cover"
                        alt="prospektus-partners-icon"
                    />
                </div>
            </section>

            <section class="bg-light container-page py-7 sm:py-12">
                <p
                    class="font-inter font-semibold text-lg leading-7 text-center text-content sm:w-[600px] mx-auto"
                >
                    Desde 2019 participamos em diversas projectos como subempreiteiro dos nossos
                    clientes, em diversos projectos de construção de estações de radio base,
                    infraestrutura de fibra óptica, manutenção e instalação de sistema AVAC.
                </p>
            </section>

            <section class="container-page my-28 sm:max-w-6xl mx-auto">
                <h1 class="h1" id="contactUs">Contacte-nos</h1>
                <form>
                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
                        <div>
                            <input
                                type="text"
                                v-model="form.name.value"
                                class="form-control"
                                placeholder="Nome"
                                minlength="10"
                                @input="form.name.validate()"
                            />
                            <span class="error-message">{{ form.name.errorMessage }}</span>
                        </div>

                        <div>
                            <input
                                type="email"
                                v-model="form.email.value"
                                class="form-control"
                                placeholder="E-mail"
                                @input="form.email.validate()"
                            />
                            <span class="error-message">{{ form.email.errorMessage }}</span>
                        </div>

                        <div class="sm:col-span-2">
                            <input
                                type="text"
                                v-model="form.subject.value"
                                class="form-control"
                                placeholder="Assunto"
                                minlength="10"
                                @input="form.subject.validate()"
                            />
                            <span class="error-message">{{ form.subject.errorMessage }}</span>
                        </div>

                        <div class="sm:col-span-2">
                            <textarea
                                class="form-control resize-none"
                                cols="10"
                                rows="5"
                                placeholder="Mensagem"
                                v-model="form.message.value"
                                minlength="20"
                                @input="form.message.validate()"
                            />
                            <span class="error-message">{{ form.message.errorMessage }}</span>
                        </div>
                    </div>

                    <button
                        type="button"
                        class="btn block mx-auto mt-4 sm:inline-block sm:text-right"
                        :disabled="form.isInValid()"
                        :class="form.isInValid() ? 'cursor-not-allowed bg-gray-400' : 'bg-primary'"
                        @click="sendMail()"
                    >
                        Enviar mensagem
                    </button>
                </form>
            </section>
        </main>

        <TheFooter
            :facebook="socials.facebook"
            :whatsapp="socials.whatsapp"
            :instagram="socials.instagram"
            :linkedin="socials.linkedin"
        />

        <Portfolio :images="carouselBanners" ref="portfolioDialogRef" />
    </div>
</template>
