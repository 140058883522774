<script setup lang="ts">
defineProps(["facebook", "whatsapp", "instagram", "linkedin"]);

const currentYear = () => {
    return new Date().getFullYear();
};
</script>

<template>
    <footer class="bg-b text-light text-center sm:text-left container-page">
        <nav class="py-8 grid grid-cols-1 sm:grid-cols-3 mx-auto gap-4">
            <ul class="footer-menu border-b sm:border-0 border-light">
                <li class="list-title">Prospektus Development, Limitada</li>
                <li><a href="mailto:geral@prospektus.co.ao">geral@prospektus.co.ao</a></li>
                <li><a href="tel:+244255300747">(+244) 225 300 747</a></li>
                <li>Distrito Urbano do Kilamba Kiaxi - Luanda, Angola</li>
                <li class="leading-8">Bairro Calemba II Nº106 - Projeto Ondjoyetu</li>
            </ul>
            <ul class="footer-menu border-b sm:border-0 border-light">
                <li class="list-title">Empresa</li>
                <li><a href="./#companyMissions">Missão</a></li>
                <li><a href="./#companyValues">Valores</a></li>
                <li><a href="./#clients">Clientes</a></li>
            </ul>
            <ul class="footer-menu">
                <li class="list-title">Serviços</li>
                <li>Infraestrutura e Telecomunicações</li>
                <li>Serviços de entregas</li>
                <li>Energia</li>
                <li>Software & IT</li>
                <li>CCTV e Segurança Eletrónica</li>
            </ul>
        </nav>
    </footer>
    <div
        class="bg-primary p-3 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between container-page"
    >
        <p class="text-center text-sm">
            &copy; Copyright {{ currentYear() }} Design By Prospektus Development
        </p>

        <div class="flex justify-center gap-4">
            <a :href="facebook" target="_blank">
                <i class="text-2xl bi bi-facebook"></i>
            </a>
            <a :href="whatsapp" target="_blank">
                <i class="text-2xl bi bi-whatsapp"></i>
            </a>
            <a :href="instagram" target="_blank">
                <i class="text-2xl bi bi-instagram"></i>
            </a>
            <a :href="linkedin" target="_blank">
                <i class="text-2xl bi bi-linkedin"></i>
            </a>
        </div>
    </div>
</template>
