import { Field } from "./field";

export class Email extends Field {
    constructor() {
        super();
    }

    validate() {
        if (this.value.length === 0) {
            this.errorMessage = "";
            return;
        }

        if (!this.isValid()) {
            this.errorMessage = "O email inserido é inválido";
            return;
        }

        this.errorMessage = "";
        this.valid = this.isValid();
    }

    isValid(): boolean {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(this.value);
    }
}
