<script setup lang="ts">
const dialogRef = ref<HTMLDialogElement>();

function show() {
    dialogRef.value?.showModal();
}

function close() {
    dialogRef.value?.close();
}

defineExpose({ show, close });
</script>
<template>
    <dialog ref="dialogRef" class="bg-white w-full h-full backdrop:bg-white overflow-visible">
        <div class="flex justify-end items-center">
            <span
                role="button"
                class="material-symbols-outlined text-primary text-4xl"
                @click="close()"
            >
                close
            </span>
        </div>

        <slot></slot>
    </dialog>
</template>
