<script setup lang="ts">
import type { BaseDialog } from "#build/components";

enum PageSection {
    AboutUs = "./#aboutUs",
    Services = "./#services",
    Clients = "./#clients",
}

const dialogRef = ref<typeof BaseDialog>();

function show() {
    dialogRef.value?.show();
}

function close() {
    dialogRef.value?.close();
}

async function openSection(path: string) {
    close();
    await navigateTo(path);
}

defineExpose({ show, close });
</script>
<template>
    <BaseDialog ref="dialogRef">
        <div class="flex h-full flex-wrap justify-center items-center text-content text-center">
            <ul>
                <li class="menu-item" @click="openSection(PageSection.AboutUs)">Quem somos</li>
                <li class="menu-item" @click="openSection(PageSection.Services)">Serviços</li>
                <li class="menu-item" @click="openSection(PageSection.Clients)">Clientes</li>
                <li class="menu-item">Portfolio</li>
            </ul>
            <p>Distrito Urbano do Kilamba Kiaxi Bairro Calemba II Nº106, Projeto Ondjoyetu</p>
        </div>
    </BaseDialog>
</template>
